/*
 * @Author: 智客云网络科技
 * @Date: 2021-04-19 11:07:53 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-22 00:05:10
 * @Description: 页面专属接口管理
 * @FilePath: \src\views\Frame\Account\api.js
 */

import http from "@/api";

// 新建账号
export const createNewAccount = (params) =>
  http.post("/v1/Account/CreateAccount", params);

// 删除账号
export const delAccountByAdmin = (params) =>
  http.delete("/v1/Account/DelAccountByAdmin", params);

// 修改账号
export const updateAccountInfoByAdmin = (params) =>
  http.put("/v1/Account/UpdateAccountInfoByAdmin", params);

// 根据UID查询账号信息
export const getAccountInfo = (params) =>
  http.get("/v1/Account/GetAccountInfoById", params);

// 获取账号表格
export const getUserTable = (params) =>
  http.get("/v1/Account/GetAccountTable", params);
