<!--
 * @Author: 智客云网络科技
 * @Date: 2021-04-18 17:51:34 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 16:11:29
 * @Description: 新建 修改 账号
 * @FilePath: \src\views\Frame\Account\NUAccount.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible.sync="isShow"
    width="950px"
    top="10vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="handleClose"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-card
        shadow="never"
        v-loading="loading"
        element-loading-text="拼命加载中..."
      >
        <el-form :model="m" :inline="true" ref="m" label-width="100px">
          <el-form-item label="用户名：" style="width: 45%">
            <el-input
              size="small"
              v-model="m.username"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="昵称：" style="width: 45%">
            <el-input
              size="small"
              v-model="m.nickname"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="密码：" style="width: 45%">
            <el-input
              size="small"
              v-model="m.password"
              type="password"
              suffix-icon="xxxx"
            >
            </el-input>
          </el-form-item>

          <el-form-item label="手机号：" style="width: 45%">
            <el-input
              size="small"
              v-model="m.phone"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="邮箱：" style="width: 45%">
            <el-input
              size="small"
              v-model="m.email"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="状态：" style="width: 45%">
            <el-switch
              size="small"
              v-model="m.status"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>

          <div class="from-rightselect-box" v-loading="role_loading">
            <el-form-item label="角色分配：">
              <el-card shadow="never">
                <el-tree
                  ref="role-tree"
                  show-checkbox
                  :data="allRoleList"
                  :props="{ label: 'name' }"
                  node-key="krole_id"
                  highlight-current
                  :default-checked-keys="roleSelectList"
                >
                </el-tree>
              </el-card>
            </el-form-item>
          </div>

          <div class="from-rightselect-box" v-loading="role_loading">
            <el-form-item label="权限分配：">
              <el-card shadow="never">
                <el-tree
                  ref="right-tree"
                  show-checkbox
                  :data="allRightList"
                  :props="{ children: 'children', label: 'name' }"
                  node-key="right_id"
                  :render-content="renderContent"
                  @node-expand="handleExpand"
                  highlight-current
                  :default-checked-keys="rightSelectList"
                >
                </el-tree>
              </el-card>
            </el-form-item>
          </div>

          <div class="from-rightselect-box" v-loading="group_loading">
            <el-form-item label="用户组分配：">
              <el-card shadow="never">
                <el-tree
                  ref="group-tree"
                  show-checkbox
                  :data="allGroupList"
                  :props="{ label: 'name' }"
                  node-key="kgroup_id"
                  highlight-current
                  :default-checked-keys="groupSelectList"
                >
                </el-tree>
              </el-card>
            </el-form-item>
          </div>
        </el-form>

        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="_CreateNewAccount()"
          v-if="id == 0"
          >提交</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-check"
          @click="_UpdateAccount()"
          v-else
          >保存</el-button
        >
        <el-button size="small" icon="el-icon-close" @click="isShow = false"
          >取消</el-button
        >
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
import {
  createNewAccount,
  updateAccountInfoByAdmin,
  getAccountInfo,
} from "./api"; //页面专有接口
import { getRightTable, getRoleTable, getGroupTable } from "../Auth/api"; //页面专有接口
export default {
  data() {
    return {
      m: {
        username: "", //用户名
        nickname: "", //昵称
        phone: "", //手机号
        email: "", //邮箱
        password: "", //密码
        vdpassword: "", //确认密码
        status: 1, //是否启用
        right_list: "", //权限列表
        role_list: "", //角色列表
        group_list: "", //用户组列表
      },
      id: 0, //
      title: "", //弹窗标题
      isShow: false, //显示控制
      loading: true, //加载控制
      kor_id: null, //组织ID
      role_loading: true, //加载控制
      right_loading: true, //加载控制
      group_loading: true, //加载控制
      allRightList: [], //所有权限列表
      rightSelectList: [], //选中的权限
      allRoleList: [], //所有角色列表
      roleSelectList: [], //选中的角色
      allGroupList: [], //所有角色列表
      groupSelectList: [], //选中的角色
    };
  },
  methods: {
    // 打开
    open(uid, kor_id = null) {
      this.isShow = true;
      this.id = uid; //重写ID
      this.kor_id = kor_id; //组织ID
      this._GetAllRightList(); //加载权限列表
      this._GetAllRoleList(); //加载角色列表
      this._GetAllGroupList(); //加载用户组列表
      if (uid === 0) {
        //0 新建
        this.title = "添加用户";
        this.loading = false;
      } else {
        //修改
        this.title = "修改用户";
        this.$nextTick(() => {
          this._GetAccountInfo(uid); //获取用户信息
        });
      }
    },

    //添加用户
    _CreateNewAccount() {
      let m = this.m;

      //权限
      m.right_list = ""; //重置权限列表
      this.$refs["right-tree"].getCheckedKeys().forEach(function (id) {
        m.right_list += id + ",";
      });

      //角色
      m.role_list = ""; //重置角色列表
      this.$refs["role-tree"].getCheckedKeys().forEach(function (id) {
        m.role_list += id + ",";
      });

      //用户组
      m.group_list = ""; //重置用户组列表
      this.$refs["group-tree"].getCheckedKeys().forEach(function (id) {
        m.group_list += id + ",";
      });
      const params = {
        kor_id: this.kor_id,
        username: m.username,
        nickname: m.nickname,
        password: m.password,
        phone: m.phone,
        email: m.email,
        status: m.status,
        kr_list: m.right_list,
        ko_list: m.role_list,
        kg_list: m.group_list,
      };
      createNewAccount(params)
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //修改用户
    _UpdateAccount() {
      let m = this.m;

      //权限
      m.right_list = ""; //重置权限列表
      this.$refs["right-tree"].getCheckedKeys().forEach(function (id) {
        m.right_list += id + ",";
      });

      //角色
      m.role_list = ""; //重置角色列表
      this.$refs["role-tree"].getCheckedKeys().forEach(function (id) {
        m.role_list += id + ",";
      });

      //用户组
      m.group_list = ""; //重置用户组列表
      this.$refs["group-tree"].getCheckedKeys().forEach(function (id) {
        m.group_list += id + ",";
      });
      const params = {
        user_id: this.id, //uid
        username: m.username,
        nickname: m.nickname,
        password: m.password,
        phone: m.phone,
        email: m.email,
        status: m.status,
        kr_list: m.right_list,
        ko_list: m.role_list,
        kg_list: m.group_list,
      };
      updateAccountInfoByAdmin(params)
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //根据UID获取账号数据
    _GetAccountInfo() {
      let m = this.m;
      const params = {
        user_id: this.id, //账号uid
      };
      getAccountInfo(params)
        .then((res) => {
          let d = res.data;
          m.username = d.username; //用户名
          m.nickname = d.nickname; //用户昵称
          m.phone = d.phone; //手机号
          m.email = d.email; //邮箱
          m.status = d.status; //状态
          this.rightSelectList = Object.values(d.right_list); //权限列表
          this.roleSelectList = d.role_list; //角色列表
          this.groupSelectList = d.group_list; //用户组
          this.loading = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //获取所有权限列表
    _GetAllRightList() {
      getRightTable({
        pageNo: 1,
        pageSize: 999999,
      })
        .then((res) => {
          this.allRightList = res.data.data; //写入列表
          setTimeout(() => {
            this.right_loading = false;
          }, 300);
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
          setTimeout(() => {
            this.right_loading = false;
          }, 300);
        });
    },

    //获取所有角色列表
    _GetAllRoleList() {
      getRoleTable({
        kor_id: this.kor_id,
        pageNo: 1,
        pageSize: 999999,
      })
        .then((res) => {
          this.allRoleList = res.data.data; //写入列表
          setTimeout(() => {
            this.role_loading = false;
          }, 300);
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
          setTimeout(() => {
            this.role_loading = false;
          }, 300);
        });
    },

    //获取所有用户组列表
    _GetAllGroupList() {
      getGroupTable({
        kor_id: this.kor_id,
        pageNo: 1,
        pageSize: 999999,
      })
        .then((res) => {
          this.allGroupList = res.data.data; //写入列表
          setTimeout(() => {
            this.group_loading = false;
          }, 300);
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
          setTimeout(() => {
            this.group_loading = false;
          }, 300);
        });
    },

    //关闭
    handleClose() {
      Object.assign(this.$data, this.$options.data());
      this.$parent._Load(); //通知父组件刷新数据
    },

    /************** Tree底层横向排列用的 **************/

    //节点被展开时触发
    handleExpand() {
      //因为该函数执行在renderContent函数之前，所以得加this.$nextTick()
      this.$nextTick(() => {
        this.changeCss();
      });
    },

    //绘制节点内容
    renderContent(h, { node }) {
      //树节点的内容区的渲染 Function
      let classname = "";

      if (node.childNodes.length === 0) {
        classname = "foo";
      }

      return [
        h(
          "span",
          {
            class: classname,
          },
          node.label
        ),
      ];
    },

    //重绘CSS
    changeCss() {
      var levelName = document.getElementsByClassName("foo"); // levelname是上面的最底层节点的名字
      for (var i = 0; i < levelName.length; i++) {
        // cssFloat 兼容 ie6-8  styleFloat 兼容ie9及标准浏览器
        levelName[i].parentNode.style.cssFloat = "left"; // 最底层的节点，包括多选框和名字都让他左浮动
        levelName[i].parentNode.style.styleFloat = "left";
        levelName[i].parentNode.onmouseover = function () {
          this.style.backgroundColor = "#fff";
        };
      }
    },
  },
};
</script>

<style></style>
